<!--该页面已废除，可根据后续要求在做修改-->

<template>
  <div>
    <Container v-if="isshow" :mainpagedata="mainpagedata" :mydefault="mydefault"
               v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"
               v-on:getpageinfo="getPageinfoEvent"></Container>
  </div>
</template>

<script>
import Container from '@/components/Container.vue'
import axios from "axios";
import {ElLoading} from 'element-plus'

export default {
  name: "memberactive",
  data() {
    return {
      mainpagedata: {
        mainonename: '会员活动', // 当前页名称
        mainoneiconshow1: false, // 当前页-【刷新按钮】
        mainoneiconshow2: false, // 当前页-【返回按钮】
        maintwoiconshow: false, // 筛选-【高级筛选按钮】
        maintwoforminfo: {
          myinputoneisshow: true, //筛选查询-左侧字+input
          myinputone:{
            divinterval: '0', //框右侧距离
            titlewidth: '95px',  //标题宽度
            inputwidth: '220px',  //input宽度
            inputinterval: '40px', //input右侧距离
            input: [
              {
                ismust: true, //是否必填，true表示必填
                title: '输入搜索：',
                placeholder: '申请人号码',
                name: 'keywords',
                content: '',
              }
            ],
          }
        }, // 筛选-控件信息
        mainthreeisshowbut: false, // 数据列表中-【添加按钮】
        mainthreeisshowset: false, // 数据列表中-复选框
        mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
        mainthreetableope: 27, // 数据列表中-操作-根据页面显示控件（整页）
        mainthreehandle: 200, // 数据列表中-操作-宽度
        mainthreetabletitle: [
          {
            fixedstatu: 'left',
            prop: 'userid',
            label: '用户ID',
            width: '350'
          },
          {
            fixedstatu: false,
            prop: 'username',
            label: '昵称',
            width: '350'
          },
          {
            fixedstatu: false,
            prop: 'userphone',
            label: '申请人号码',
            width: '400'
          },
          {
            fixedstatu: false,
            prop: 'applytime',
            label: '申请时间',
            width: '460'
          },

        ], // 数据列表中-数据表头
        mainthreestatus: true, // 数据列表中-属于search按钮表示状态
        mainthreetableData: [],// 数据列表中-具体数据
        totalCount: 1, //数据列表中-数据总条数
        lastpage: 1, //数据列表中-数据总页数（默认以20条一页）

        //高级筛选
        myinputishow: true, //高级筛选-input单框
        myinput: {
          inputwidth: '100%',
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: '输入搜索：',
              placeholder: '代理商名称/手机号',
              name: 'keywords',
              content: ''
            }
          ]
        },
        myselectisshow: true, //高级筛选-select单框
        myselect: {
          selectwidth: '100%',
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: '代理商等级：',
              placeholder: '全部',
              name: 'agentlevel',
              value: '',
              options: [
                {
                  value: '0',
                  label: '全部',
                },
                {
                  value: '1',
                  label: '一级代理',
                },
                {
                  value: '2',
                  label: '二级代理',
                },
                {
                  value: '3',
                  label: '三级代理',
                },
              ],
            },
            {
              selectisshow: true,
              ismust: false,
              title: '状态:',
              placeholder: '全部',
              name: 'status',
              value: '',
              options: [
                {
                  value: '0',
                  label: '全部',
                },
                {
                  value: '1',
                  label: '启用',
                },
                {
                  value: '-1',
                  label: '禁止',
                },
              ],
            },
            {
              selecttimeisshow: true,
              ismust: true,
              title: '添加时间：',
              placeholder: '请选择时间',
              name: 'addtime',
              value: ''
            }
          ]
        },
        myselectcityisshow: true, //高级筛选-selectcity选框
        myselectcity: {
          selectwidth: '100%',
          select: {
            ismust: true,
            name: 'citycode',
            valuesheng: '',
            valueshi: '',
            valuequ: '',
            disshi: true,
            disqu: true,
          }
        }
      }, // 当前Pagedata所需的data数据与控制
      mydefault: {
        myheadactive: "a5",    //该字段为string类型，是头部导航key值
        myasideactive: "68",  //该字段为string类型，是子页面的id
      }, //本页面的导航默认值-逆向绑定（可优化-通过当前页面的path值，对数据进行筛选对比，将值自动赋值，避免修改路由之后出现页面&导航不对应状况）
      mytoken: localStorage.getItem('token'),  //获取存储的token
      isshow: true  //用于判断页面模块是否存在
    }
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false
      this.$router.push('/').catch(error => error)
    } else {
      //通过Ajax向后台获取数据
      axios.post('/admin/shopUser/index',
          {
            addtime: '2020-10-01~2021-10-31' //该字段为必传项-当空值，表示查询当月数据
          },
          {
            headers: {
              'Authentication': this.mytoken
            }
          })
          .then(response => {
            this.myaxiosfunc(response.data.result)
          })
          .catch(function (error) {
            console.log(error)
          })
    }
  },
  mounted() {

  },
  methods: {
    searchFormEvent(data) { //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
      console.log('高级筛选-开始检索返回数据(页面):' + JSON.stringify(data))
      //加载loading
      var loading = ElLoading.service({
        lock: true,
        text: '正在飞速加载中...',
      })
      //通过Ajax向后台获取数据
      axios.post('/admin/shopUser/index',
          {
            keyword: data.keywords,
            status: data.status,
            level: data.agentlevel,
            area_code: data.citycode,
            addtime: '2020-10-01~2021-10-31'
          },
          {
            headers: {
              'Authentication': this.mytoken
            }
          })
          .then(response => {
            this.myaxiosfunc(response.data.result)
            loading.close()
          })
          .catch(function (error) { // 请求失败处理
            console.log(error)
          })

    },
    getSeachinfoEvent(data) { //获取Container-Pagedata-Maintwo-搜索-返回的数据
      console.log('点击查询获取的data数组：' + JSON.stringify(data))
    },
    getPageinfoEvent(data) { //获取Container-Pagedata-Mainthree-数据列表-分页选择的数据

      //加载loading
      var loading = ElLoading.service({
        lock: true,
        text: '正在飞速加载中...',
      })
      //通过Ajax向后台获取数据
      axios.post('/admin/shopUser/index',
          {
            page: data,
            addtime: '2020-10-01~2021-10-31'
          },
          {
            headers: {
              'Authentication': this.mytoken
            }
          })
          .then(response => {
            this.myaxiosfunc(response.data.result)
            loading.close()
          })
          .catch(function (error) { // 请求失败处理
            console.log(error)
          })
    },
    myaxiosfunc(successdata) {
      var newdata = successdata.data
      for (var i = 0; i < newdata.length; i++) {
        //该字段表示search按钮，且status为1表示启用，2表示禁用
        newdata[i].switchstatus = 4
        //将省市区分割
        var arrcity = newdata[i].area_code.split(',');
        newdata[i].province = arrcity[0];
        newdata[i].city = arrcity[1];
        newdata[i].county = arrcity[2]
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainpagedata.mainthreetableData = newdata;
      this.mainpagedata.totalCount = successdata.total
      this.mainpagedata.lastpage = successdata.last_page
    }
  },
  components: {
    Container,
  }
}
</script>

<style scoped>

</style>